<template>
  <LoadingMingguanForm mode="Ubah" module="Data Loading Mingguan"> </LoadingMingguanForm>
</template>

<script>
import LoadingMingguanForm from './form';

const LoadingMingguanUpdate = {
  name: 'LoadingMingguanUpdate',
  components: { LoadingMingguanForm },
};

export default LoadingMingguanUpdate;
</script>
